import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { StandardCard } from '../../comp/cards';
import { Faqlist } from '../../comp/lists';
import { Menu, ProductMenu } from '../../comp/menus';
import {NewProductForm} from '../../comp/forms'
import { ProductList } from '../../comp/lists';
/**
 * Page displaying the tools needed to update the FAQ on the frontpage
 * @component
 */
const EditStatusPage = ({ history }) => {

    return (
        <>
            <Menu history={history} />
            
            <Container>

                <Row>

                <Col>
                <StandardCard title={"Add product"}>

                    <NewProductForm/>
                    
                </StandardCard>

                </Col>

                <Col>
                
                
            <StandardCard title="Sort products">

            <ProductList ></ProductList>
                
            </StandardCard>
                    
                    
                
                </Col>
                </Row>
            

            </Container>
        </>
    )
}




export default EditStatusPage