import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { withRouter } from 'react-router'
import { StandardCard } from '../comp/cards'
import { app } from '../database/firebase'

/**
 * Page for reseting a forgotten password
 * @component
 */


const ResetPwPage = ({ history }) => {

  var auth = app.auth()
  const [email, setEmail] = useState("")

  const click = () => {
    auth.sendPasswordResetEmail(email).then(function () {
      alert("If there is an user with this email, we have sent a password reset link.")
      history.push('/login')
    }).catch(function (error) {
      alert("If there is an user with this email, we have sent a password reset link.")
      history.push('/login')
    });
  }


  return (
    <Container>
      <StandardCard title="Log in to Mekanisk Assist admin">
        <Form onSubmit={click}>
          <Form.Group>
            <Form.Control name="email" type="email" onChange={e => setEmail(e.target.value)} value={email} placeholder="Email address"></Form.Control>
          </Form.Group>

          <Button disabled={email === ""} variant="primary" onClick={() => click()}>
            Send password reset email
            </Button>
        </Form>

      </StandardCard>
    </Container>

  )
}

export default withRouter(ResetPwPage)