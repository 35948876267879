import firebase from 'firebase/app';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Image, NavLink, Row, Dropdown} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FileUploader from "react-firebase-file-uploader";
import ReCAPTCHA from "react-google-recaptcha";
import { GDPRmodal } from '../comp/modals';
import { firestore, storage } from '../database/firebase';
import { GenericLoadingAnimation } from './loadingAnimations';
import DatePicker from 'react-date-picker'
const assist_config = require('../assist_config.json')

/**
 * Customer form for taking input from the customer and sending to the database. 
 *
 * @component
 */

const CustomerForm = ({ children, history }) => {
    // state variables for user input
    const [email1, setEmail1] = useState('')
    const [email2, setEmail2] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [content, setContent] = useState('')
    const [checkErrors, setCheckErrors] = useState(false)
    const [showGDPRModal, setShowGDPRModal] = useState(false)


    const firstRef = useRef(null)
    const submitButtonRef = useRef(null)

    useEffect(() => {
         firstRef.current.focus();
         submitButtonRef.current.scrollIntoView()
      }, []);

    //file upload states
    const [fileUpload, setFileUpload] = useState({
        email: "",
        isUploadng: false,
        progress: 0,
        URL: "",
        current: ""
    })


    // state variables for form validatation
    const [reCaptcha, setReCaptcha] = useState(false)
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [GDPR, setGDRP] = useState(false)
    const [noOrderNumber, setNoOrdernumber] = useState(false)

    const handleUploadSuccess = filename => {
        setFileUpload({ current: filename, progress: 100, isUploading: false })
        console.log(filename)
        storage
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => setFileUpload({ URL: url }))

    }

    const click = () => {
        setStage(2)
    }

    const submitTicket = () => {
        if (fileUpload.URL === undefined) return alert("Please wait until the image is uploaded.")

        setLoading(true)
        firestore.collection("tickets").add({
            email: email1,
            orderNumber: orderNumber,
            content: content,
            category: 0,
            created: firebase.firestore.Timestamp.now(),
            solved: false,
            assigned: "none",
            images: {
                image1: fileUpload.URL
            }
        })
            .then(function () {
                setLoading(false)
                setStage(3)

            })
            .catch(function (error) {
                alert("Database error, please check your internet connection or try again.")
                setLoading(false)
            })
    }



    const validateOrderNumber = () => {

        const orderNumberRX = RegExp(/^[0-9]{4}$/)

        if (!noOrderNumber) {
            if (!orderNumberRX.test(orderNumber)) {
                return false
            }
        }
        return true
    }

    const validateEmail = () => {
        return email1 === email2
    }

    const lengthOfString = (text, length) => {
        if (text == null) return false
        return text.length >= length
    }

    const validateForm = () => {

        return validateOrderNumber() && reCaptcha && GDPR && !fileUpload.isUploadng && validateEmail() && lengthOfString(content, 5) && lengthOfString(email1, 4)
    }

    if (loading) return <GenericLoadingAnimation />


    if (stage === 2) {
        return (
            <>

                <Card.Text>If you want, you can attach an image with your ticket. It will automatically be cropped to 2000x1500px.</Card.Text>
                <Row>
                    <FileUploader
                        accept="image/*"
                        name="avatar"
                        maxWidth="2000"
                        maxHeight="1500"
                        randomizeFilename
                        storageRef={storage.ref("images")}
                        onUploadStart={() => setFileUpload({
                            isUploading: true,
                            progress: 0
                        })}
                        onUploadError={() => setFileUpload({ isUploadng: false })}
                        onUploadSuccess={(filename) => handleUploadSuccess(filename)}
                        onProgress={console.log("aa")}
                    />
                </Row>

                <Row>

                    {fileUpload.isUploading && <GenericLoadingAnimation />}
                    {fileUpload.URL && <Image fluid width="400" src={fileUpload.URL} />}


                    <Row>{fileUpload.isUploading && <Card.Text className="text-red">Please wait for the upload to finish, or cancel the upload.</Card.Text>}</Row>
                </Row>


                <Row><br /></Row>

                <Row>
                    <Button variant="danger" disabled={fileUpload.URL === "" || loading || !validateForm()} onClick={() => setFileUpload({ URL: "" }, { current: "" })}>Remove image</Button>
                    <Button disabled={fileUpload.isUploading || loading || !validateForm()} onClick={() => submitTicket()}>Submit ticket</Button>

                </Row>
            </>
        )
    }

    if (stage === 3) {
        return (
            <>
                <Card.Text>Thanks for your submission. You will receive a mail confirming that the ticket was received.</Card.Text>
                <Button onClick={() => window.location.reload()}>Return to home page</Button>
            </>
        )
    }

    return (
        <>
            <GDPRmodal show={showGDPRModal} setShow={setShowGDPRModal}></GDPRmodal>
            <Form onSubmit={click}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control ref={firstRef} type="email" onChange={e => setEmail1(e.target.value)} value={email1} placeholder="Email address"></Form.Control>
                        <Form.Text hidden={!checkErrors || validateEmail()} className="text-danger">Both emails must match.</Form.Text>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>Confirm email address</Form.Label>
                        <Form.Control type="email" onChange={e => setEmail2(e.target.value)} value={email2} placeholder="Confirm your email address"></Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Row>

                    <Form.Group as={Col}>
                        <Form.Label>Order number (#MEKANISK XXXX)</Form.Label>
                        <Form.Control disabled={noOrderNumber} maxLength="4" type="phonenumber" onChange={e => setOrderNumber(e.target.value)} value={orderNumber} placeholder="XXXX" ></Form.Control>
                        <Form.Check controlId="noOrderNumber" onChange={() => setNoOrdernumber(!noOrderNumber)} type="checkbox" label="I don't have a order number" />
                        <Form.Text hidden={!checkErrors || noOrderNumber || validateOrderNumber()} className="text-danger">Check that the order number is formatted correctly. No letters are allowed.</Form.Text>
                    </Form.Group>


                    <Form.Group as={Col}></Form.Group>

                </Form.Row>

                <Form.Group>
                    <Form.Label>Describe your issue</Form.Label>
                    <Form.Control as="textarea" onChange={e => setContent(e.target.value)} value={content} placeholder="Please describe as detailed as you can" maxLength="512" />
                    <Form.Text hidden={!checkErrors || lengthOfString(content, 5)} className="text-danger">Please provide a description. Make sure the description does to exceed 255 characters.</Form.Text>
                </Form.Group>


                <Form.Group>
                    <ReCAPTCHA aria-label="ReCaptcha / spam checkbox" sitekey="6LdU99kZAAAAAKeh1uXM_YQ-6mjJXZSkaCu_4yvs" onChange={() => setReCaptcha(true)} />
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox">


                    <Form.Check aria-label="GDPR compliance checkbox" type="checkbox" onClick={() => setCheckErrors(true)} onChange={e => setGDRP(!GDPR)} label="Accept that we use your email to contact you regarding this support ticket (GDPR)" />
                    <NavLink onClick={() => setShowGDPRModal(true)}>Read GDPR</NavLink>
                </Form.Group>


                <Button ref={submitButtonRef} disabled={loading || !validateForm()} variant="primary" type="submit">
                    Next
            </Button>


            </Form>
        </>
    )
}



/**
 * Customer support access form for editing the FAQ and add questions/answers to the FAQ
 *
 * @component
 */

const EditFAQForm = ({ history }) => {

    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')

    const clickAdd = () => {

        firestore.collection("faq").add({
            question: question,
            answer: answer,
            created: firebase.firestore.Timestamp.now()
        })
            .then(function () {
                console.log("Your FAQ entry was recorded")

            })
            .catch(function (error) {
                alert("Error, please check your internet connection.")
            })
    }

    return (
        <Form>

            <Form.Group>
                <Form.Control type="text" onChange={e => setQuestion(e.target.value)} value={question} placeholder="Question"></Form.Control>
            </Form.Group>

            <Form.Group>

                <Form.Control rows={4} maxLength="255" onChange={e => setAnswer(e.target.value)} value={answer} as="textarea" placeholder="Answer"></Form.Control>
            </Form.Group>

            <Button variant="primary" onClick={() => clickAdd()}>
                Add to FAQ
            </Button>

        </Form>
    )
}




/**
 * Customer support access form for editing the FAQ and add questions/answers to the FAQ
 *
 * @component
 */

const NewProductForm = ({ history }) => {

    const [newProduct, setNewproduct] = useState({
        product_name: "",
        product_desc: "",
        product_stage: 0,
        product_link: "",
        estimated_shipping: new Date()
    })
 

    const StatusTerms = assist_config.StatusTerms
    

    const clickAdd = () => {

        firestore.collection(assist_config.company).doc("assets").collection("products").add({
            product_name: newProduct.product_name,
            product_desc: newProduct.product_desc,
            product_stage: newProduct.product_stage,
            product_link: newProduct.product_link,
            estimated_shipping: newProduct.estimated_shipping
        })
            .then(function () {
                

            })
            .catch(function (error) {
                alert(error +"Error, please check your internet connection.")
            })
    }

    const verifyForm = () => {

        if(newProduct.product_name.length<10) return false //&& newProduct.product_desc >1 && newProduct.product_stage >= 0 && newProduct.product_link > 1 && newProduct.estimated_shipping!=undefined
    }

    return (
        <Form>

            <Form.Group>
                <Form.Control type="text" maxLength="255" onChange={e => setNewproduct({...newProduct, product_name : e.target.value})} value={newProduct.product_name} placeholder="Product name"></Form.Control>
            </Form.Group>

            <Form.Group>
                <Form.Control type="text" as="textarea" rows={4} maxLength="255" onChange={e => setNewproduct({...newProduct, product_desc : e.target.value})} value={newProduct.product_desc} placeholder="Product description"></Form.Control>
            </Form.Group>


            <Form.Group>
                <Form.Control type="text" maxLength="255" onChange={e => setNewproduct({...newProduct, product_link : e.target.value})} value={newProduct.product_link} placeholder="Product link"></Form.Control>
            </Form.Group>


            <Row>
                <Col>
            <br/><br/>
            <Card.Text>Estimated shipping date</Card.Text>
            <DatePicker value={newProduct.estimated_shipping} onChange={date => setNewproduct({...newProduct, estimated_shipping : date})} />
            </Col>

            <Col>

            <br/><br/>
            <Card.Text>Product status</Card.Text>
            <Dropdown>
                <Dropdown.Toggle variant="ultramarine" id="dropdown-basic">
                    {StatusTerms[newProduct.product_stage]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    
                    {StatusTerms.map((term, index)=>
                    <Dropdown.Item key={index} onClick={()=> setNewproduct({...newProduct, product_stage:index})}>{term}</Dropdown.Item>
                    
                    )}
                
                </Dropdown.Menu>
                </Dropdown>

                </Col>

                </Row>

            <br/><br/><br/><br/>
            <Button disabled={verifyForm()} variant="primary" onClick={()=>clickAdd()}>
                Add to products
            </Button>

        </Form>
    )
}


export { NewProductForm, CustomerForm, EditFAQForm };



