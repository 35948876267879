import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const GenericErrorAlert = ({ subText, title, hidden }) => {
  const [show, setShow] = useState(true)

  if (show) {
    return (
      <Alert hidden={hidden} variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{title}</Alert.Heading>
        {subText}
      </Alert>
    )
  }
  return null
}


export { GenericErrorAlert };
