import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FocusedTicketCard, StandardCard } from '../../comp/cards';
import { AllTicketsList } from '../../comp/lists';
import { Menu } from '../../comp/menus';



/**
 * Page displaying the solving tools for the customer service employee
 * @component
 */
const DashBoard = ({ history }) => {
    return (
        <>
            <Menu history={history} />
            <Container fluid>
                <div className="Dashboard">
                    <Row className="justify-content-md-center">

                        <Col sm="12" lg="6" xl="5">

                            <StandardCard title="Pick tickets - all unsolved tickets" subText="These tickets are visible for all customer service employees. Click on a ticket to assign it to yourself.">
                                <AllTicketsList />
                            </StandardCard>

                        </Col>

                        <Col sm="12" lg="6" xl="6">
                            <StandardCard title="Solve tickets - your picks" subText="These tickets are only visible for you">
                                <FocusedTicketCard />
                            </StandardCard>
                        </Col>

                    </Row>
                </div>
            </Container>
        </>
    )
}




export default DashBoard