import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from './database/Auth';
import PrivateRoute from './database/PrivateRoute';
import LogInPage from './pages/loginpage';
import ResetPwPage from './pages/resetPwPage';
import ArchivePage from './pages/secured/archivePage';
import Dashboard from './pages/secured/dashboard';
import EditFaqPage from './pages/secured/editFaqPage';
import SignUpPage from './pages/secured/signuppage';
import userPage from './pages/secured/userPage';
import EditStatusPage from './pages/secured/editStatusPage';

/**
 * Routing for the entire web application. The routing is wrapped in the AuthProvider connected to Firebase.
 * @component
 */

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="Mekanisk Assist">
          <Route exact path="/" component={LogInPage}/>
          <Route exact path="/resetpw" component={ResetPwPage}/>
          {
            /* Protected path, requires authentication */
          }
          <PrivateRoute exact path="/dashboard" component={Dashboard}/>
          <PrivateRoute exact path="/signup" component={SignUpPage}/>
          <PrivateRoute exact path="/editfaq" component={EditFaqPage}/>
          <PrivateRoute exact path="/archive" component={ArchivePage}/>
          <PrivateRoute exact path="/user" component={userPage}/>
          <PrivateRoute exact path="/editStatusPage" component={EditStatusPage}/>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;