import React from 'react';
import { Nav, Navbar, Image } from 'react-bootstrap';
import { SignOutButton } from './buttons';
import whiteFullLogo from '../assets/06.png';


/**
 * Main navigation menu
 * @component
 *
 */

const Menu = ({ history }) => {
    return (
        <>

            <Navbar className="mb-2" bg="dark" variant="dark">

                <Nav className="mr-auto">
                    <Nav.Link onClick={() => history.push('/dashboard')}>Dashboard</Nav.Link>
                    <Nav.Link onClick={() => history.push('/archive')}>Archive</Nav.Link>
                    <Nav.Link onClick={() => history.push('/editfaq')}>FAQ</Nav.Link>
                    <Nav.Link onClick={() => history.push('/editStatusPage')}>Products</Nav.Link>
                    <Nav.Link onClick={() => history.push('/signup')}>Create user</Nav.Link>
                    
                </Nav>

                <Nav.Link onClick={() => history.push('/user')}>My account</Nav.Link>
                <SignOutButton />
            </Navbar>
        </>
    )
}

const ProductMenu = ({ history }) => {
    return (
        <>

            <Navbar className="mb-2" bg="dark" variant="dark">

                <Nav className="mr-auto">
                    <Nav.Link onClick={() => history.push('/editStatusPage')}>Products</Nav.Link>
                </Nav>

                
            </Navbar>
        </>
    )
}

const FrontMenu = ({history}) => {
    return (
        <>
        

            <Navbar className="mb-2" bg="dark" variant="dark">



            <Navbar.Brand>

            <Image width="300px" aria-label="Mekanisk logo" fluid src={whiteFullLogo}></Image>
            </Navbar.Brand>

            </Navbar>
        </>
    )
}

export {ProductMenu, Menu, FrontMenu };
