import React from 'react';
import Button from 'react-bootstrap/Button';
import { app, firestore } from '../database/firebase';

/**
 * Button for signing out
 * @component
 *
 */

const SignOutButton = () => {
  return (
    <Button variant="danger" onClick={() => app.auth().signOut()}>Sign out</Button>
  )
}


/**
 * Button for solving a ticket
 * @component
 * @param {string} title Button title
 * @param {string} docuementId Document identification
 *
 */
const SolveTicketButton = ({ title, documentId }) => {
  const setTicketSolved = () => {
    firestore.collection("tickets").doc(documentId).update({
      solved: true,
    })
      .then(function () {
        return true
      })
      .catch(function (error) {
        return false
      })
  }


  return (
    <Button variant="danger" onClick={() => setTicketSolved()}>{title}</Button>
  )
}

/**
 * Button for deleting a FAQ-entry
 * @component
 * 
 * @param {string} documentId DocumentID for the document to be deleted.
 * @param {string} title Title of the button.
 *
 */

const DeleteFaqListButton = ({ documentId, title }) => {

  const deleteFaq = () => {
    firestore.collection("faq").doc(documentId).delete().then(function () {
      alert("Deleted question/answer from the public FAQ-list.")
    }).catch(function (error) {
      alert("Error removing")
    });
  }

  return (
    <Button variant="danger" onClick={() => deleteFaq()}>{title}</Button>
  )
}

/**
 * Button for defocusing a ticket from a CS-rep
 * @component
 *
 */

const DeFocusTicketButton = ({ title, documentId, children }) => {

  const deFocusTicket = () => {
    firestore.collection("tickets").doc(documentId).update({
      assigned: "none",
    })
  }

  return (
    <Button variant="ultramarine" onClick={() => deFocusTicket()}>{title}{children}</Button>
  )

}


export { SignOutButton, SolveTicketButton, DeleteFaqListButton, DeFocusTicketButton };

