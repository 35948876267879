import firebase from 'firebase/app';
import React, { useState } from 'react';
import { Accordion, Button, Col, Dropdown, DropdownButton, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useCollection } from 'react-firebase-hooks/firestore';
import { HiArrowLeft } from 'react-icons/hi';
import { DeFocusTicketButton } from '../comp/buttons';
import { solveTicket } from '../database/db_ticket';
import { app, firestore } from '../database/firebase';
import { GenericErrorAlert } from './alerts';
import { GenericLoadingAnimation } from './loadingAnimations';
import { ConfirmationModal, EmailModal } from './modals';




const StandardCard = ({ children, title, subText }) => {
    return (
        <Card className="p-4 m-2 shadow-sm">
            <Card.Title>{title}</Card.Title>
            <small className="text-muted">{subText}</small>
            {children}
        </Card>
    )
}

const InsideCard = ({ children, title }) => {
    return (
        <Card className="p-4 mb-4 mt-4 shadow-sm">
            <Card.Title>{title}</Card.Title>
            {children}
        </Card>
    )
}

const SlimCard = ({children, title}) => {
    return (
        <Card className="p-3 mt-2 shadow-sm">
            <Card.Title>{title}</Card.Title>
            {children}
        </Card>
    )
}


const FocusedTicketCard = () => {
    var user = app.auth().currentUser
    const [value, loading, error] = useCollection(
        firestore.collection('tickets').where("assigned", "==", user.email).where("solved", "==", false).limit(10),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })


    const [showEmailModal, setShowEmailModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmSolve, setConfirmSolve] = useState(false)
    const [newNote, setNewNote] = useState("")
    const [showError, setShowError] = useState({
        show: false,
        errorMessage: ""
    })

    const newEmailModal = (email) => {
        setShowEmailModal(true)
    }

    const solveTicketConfirmation = (docId) => {
        if (confirmSolve) {
            alert(confirmSolve)
            solveTicket(docId)
        }

        setShowConfirmationModal(true)

    }



    const createNote = (docID) => {
        firestore.collection("tickets").doc(docID).update({
            notes: {
                author: user.email,
                message: newNote,
                created: firebase.firestore.Timestamp.now()
            }
        })
            .then(function () {
                setNewNote("")
                return true

            })
            .catch(function (error) {
                setShowError({ show: true, errorMessage: "Database error, please check your internet connection or try again." })
            })

    }



    if (loading) return <GenericLoadingAnimation />
    if (error) return <GenericErrorAlert title="Can't fetch tickets" subText="Please update the page and try again." />

    return (

        <>
            <GenericErrorAlert hidden={!showError.show} title={showError.errorMessage} />

            <Accordion>
                {value.docs.map(doc =>
                    <>
                        <EmailModal show={showEmailModal} setShow={setShowEmailModal} email={doc.data().email} orderNumber={doc.data().orderNumber} />
                        <ConfirmationModal docId={doc.id} setConfirm={setConfirmSolve} show={showConfirmationModal} setShow={setShowConfirmationModal} title="Solve ticket?" body="Are you sure you want to solve and archive this ticket?" />
                        <Card className="shadow-sm" border="dark">
                            <>

                                <Card.Header>
                                    <DeFocusTicketButton documentId={doc.id}><HiArrowLeft /></DeFocusTicketButton>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={doc.id}>

                                        #MEKANISK{doc.data().orderNumber} - Created {FirebaseTimestampSince(doc.data().created)}

                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey={doc.id}>
                                    <Card className="border-0 justify-content-md-center">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Card.Title>Customer message</Card.Title>
                                                    <small className="text-muted">By customer: {doc.data().email}</small>
                                                    <Card.Text>{doc.data().content}</Card.Text>


                                                </Col>

                                                <Col><Image onClick={() => window.open(doc.data().images.image1)} fluid style={{ boxShadow: "5px 5px 5px grey" }} variant="top" src={doc.data().images.image1} /></Col>

                                            </Row>
                                        </Card.Body>

                                        <Card.Body>
                                            {doc.data().notes === undefined ? <small className="text-muted">No notes are added to this ticket yet.</small> :
                                                <>
                                                    <InsideCard>

                                                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                                                        <strong className="mr-auto">Note</strong>
                                                        <small>{FirebaseTimestampSince(doc.data().notes.created)} by {doc.data().notes.author}</small>
                                                        <br />
                                                        <p>{doc.data().notes.message}</p>
                                                    </InsideCard>
                                                </>
                                            }

                                        </Card.Body>
                                        <InputGroup className="p-3">
                                            <FormControl
                                                placeholder="ex. waiting for customer to respond to email w/ shipping details"
                                                aria-label="Internal note field"
                                                aria-describedby="basic-addon2"
                                                value={newNote}
                                                onChange={e => setNewNote(e.target.value)}
                                            />
                                            <InputGroup.Append>
                                                <Button disabled={newNote === ""} onClick={() => createNote(doc.id)} variant="outline-secondary">{doc.data().notes === undefined ? "Add first note" : "Update note"}</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Card.Footer>

                                            <DropdownButton id="dropdown-basic-button" title="Actions">
                                                <Dropdown.Item onClick={() => newEmailModal(doc.data().email)}>Contact customer</Dropdown.Item>
                                                <Dropdown.Item onClick={() => solveTicketConfirmation(doc.id)}>Solve ticket</Dropdown.Item>
                                            </DropdownButton>


                                            <small className="text-muted">Will be solved as: {doc.data().assigned}</small>
                                        </Card.Footer>
                                    </Card>
                                </Accordion.Collapse>
                            </>
                        </Card>
                    </>
                )}

            </Accordion>
        </>
    )

}


const UserCard = ({ children }) => {
    let user = app.auth().currentUser
    const [changeDisplayName, setChangeDisplayName] = useState(false)

    if (user == null) return <GenericLoadingAnimation title="No user detected, please log in!" />

    return (

        <Card className="p-4 m-4">
            <Card.Text>Logged in as: {user.email}</Card.Text>
            {user.displayName == null ?
                <Button disabled={changeDisplayName} onClick={() => setChangeDisplayName(true)}>Set a display name</Button>
                :
                <Card.Text>Username: {user.displayName}</Card.Text> && <Button>Edit</Button>
            }

            {changeDisplayName ?
                <div><FormControl placeholder="New display name"></FormControl> <Button variant="success">aaa</Button> </div>
                :
                null}

            {children}
        </Card>

    )
}

const FirebaseTimestampSince = (timestamp) => {
    var d = new Date()
    var td = timestamp.toDate()

    var timeDifference = d.getTime() - td.getTime()
    var differenceInDays = timeDifference / (1000 * 3600 * 24)
    var differenceInHours = differenceInDays * 24
    var differenceInMinutes = differenceInHours * 60
    var differenceInSeconds = differenceInMinutes * 60

    if (differenceInSeconds < 60) return Math.floor(differenceInSeconds) + " seconds ago."
    if (differenceInMinutes < 60) return Math.floor(differenceInMinutes) + " minutes(s) ago."
    if (differenceInHours < 24) return Math.floor(differenceInHours) + " hours ago."
    if (differenceInDays < 30) return Math.floor(differenceInDays) + " day(s) ago."
    else {
        return " over a month ago"
    }
}

export { SlimCard, StandardCard, UserCard, InsideCard, FocusedTicketCard };

