import React, { useState, useEffect } from 'react';
import { Dropdown, ListGroupItem, Form, Card,Nav, Accordion, Button, Row, Col } from 'react-bootstrap';
import { useCollection, useCollectionOnce, useDocumentOnce } from 'react-firebase-hooks/firestore';
import { focusTicket } from '../database/db_ticket';
import { firestore } from '../database/firebase';
import { GenericErrorAlert } from './alerts';
import { DeleteFaqListButton } from './buttons';
import { InsideCard, SlimCard } from './cards';
import { GenericLoadingAnimation } from './loadingAnimations';
import DatePicker from 'react-date-picker'
const assist_config = require('../assist_config.json')



const Faqlist = ({ editable, live }) => {
    const [value, loading, error] = useCollection(
        firestore.collection("faq").orderBy('created', 'desc').limit(10),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })

    if (loading) return (<GenericLoadingAnimation />)
    if (error) return <GenericLoadingAnimation title="Can't fetch faq." />

    return (
        <div>
            {value.docs.map(doc =>
                <InsideCard action key={doc.id} title={doc.data().question}>

                    {doc.data().answer}

                    {editable ?
                        <DeleteFaqListButton title="Delete question" documentId={doc.id}></DeleteFaqListButton>
                        :
                        null
                    }

                </InsideCard>
            )}
        </div>
    )
}


const ProductList = () => {


    const [pageNumber, setPageNumber]= useState(0)
    
    
    
    const [value, loading, error] = useCollection(
        firestore.collection(assist_config.company).doc("assets").collection("products")
        .where("product_stage","==",pageNumber)
        
    )
        

        const StatusTerms = assist_config.StatusTerms

    if (error) return <GenericErrorAlert title="Can't fetch products." />
    if(value===null) return null
    return (
        <>
        {loading ? <GenericLoadingAnimation /> :
        
        <>



  <Dropdown>


  
  <Card.Text>Product status</Card.Text>
                <Dropdown.Toggle variant="ultramarine" id="dropdown-basic">
                    {StatusTerms[pageNumber]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    
                    {StatusTerms.map((term, index)=>
                    <Dropdown.Item key={index} onClick={()=> setPageNumber(index)}>{term}</Dropdown.Item>
                    
                    )}
                
                </Dropdown.Menu>
                </Dropdown>
  
  
        
        {value.docs.map((doc, index) =>
        <>

        
        <SmallProductCard  docId={doc.id} />
        
        

        </>
        
        )}
        
       
        </>
        
        }
            

        </>
    )
}

const SmallProductCard= ({docId}) =>{


    const [value, loading, error] = useDocumentOnce(
        firestore.collection(assist_config.company).doc('assets').collection('products').doc(docId),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })

        
        const [newProduct, setNewproduct] = useState({
            product_name: "",
            product_desc: "",
            product_stage: 1,
            product_link: "",
            estimated_shipping: ""
        })        


        const prepareForEdit = () =>{
            setNewproduct({
                product_name: value.data().product_name,
                product_desc: value.data().product_desc,
                product_stage: value.data().product_stage,
                product_link: value.data().product_link,
                estimated_shipping: value.data().estimated_shipping.toDate()

            })

            setEditable(true)
        }

    
        const clickAdd = () => {

            setEditable(false)

            firestore.collection(assist_config.company).doc("assets").collection("products").doc(docId).update({
                product_name: newProduct.product_name,
                product_desc: newProduct.product_desc,
                product_stage: newProduct.product_stage,
                product_link: newProduct.product_link,
                estimated_shipping: newProduct.estimated_shipping
            })
                .then(function () {
                    console.log(newProduct.product_name + " was updated.")
    
                })
                .catch(function (error) {
                    alert(error +"Error, please check your internet connection.")
                })
        }
    



    const [editable, setEditable] = useState(false)
    
    const StatusTerms = assist_config.StatusTerms


    if(loading)return (<GenericLoadingAnimation/>)
    if(error)return (<GenericErrorAlert/>)
    if(value==undefined) return (null)
    return(
    
    <Card className="m-2 shadow-sm">
    <Card.Body>
    
    
        <Card.Title>{value.data().product_name}</Card.Title>

{editable ? 
            
            <Form.Control as="textarea" type="text" maxLength="255"  value={newProduct.product_desc} onChange={e => setNewproduct({...newProduct, product_desc : e.target.value})} placeholder={value.data().product_desc}></Form.Control>

            : 
            <Card.Text>{value.data().product_desc}</Card.Text>
            }

<Card.Text>Product description</Card.Text>
{editable ? 
            
            <Form.Control type="text" maxLength="255"  value={newProduct.product_link} onChange={e => setNewproduct({...newProduct, product_link : e.target.value})} placeholder={value.data().product_desc}></Form.Control>

            : 
            <Card.Text>{value.data().product_link}</Card.Text>
            }

<Row>
    <Col>
    
    <Card.Text>Product link</Card.Text>
{editable ? 
            <DatePicker value={newProduct.estimated_shipping}  onChange={date => setNewproduct({...newProduct, estimated_shipping : date})} />
            : 
            <DatePicker disabled value={value.data().estimated_shipping.toDate()}/>
            }
            <Card.Text>Estimated shipping date</Card.Text>

</Col>
<Col>


{editable ? 
            <Dropdown>
            <Dropdown.Toggle variant="ultramarine" id="dropdown-basic">
                {StatusTerms[newProduct.product_stage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                
                {StatusTerms.map((term, index)=>
                <Dropdown.Item onClick={()=> setNewproduct({...newProduct, product_stage:index})}  key={index}>{term}</Dropdown.Item>
                
                )}
            
            </Dropdown.Menu>
            </Dropdown>

            : 
            <Dropdown>
            <Dropdown.Toggle disabled variant="ultramarine" id="dropdown-basic">
                {StatusTerms[value.data().product_stage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                
                {StatusTerms.map((term, index)=>
                <Dropdown.Item key={index}>{term}</Dropdown.Item>
                
                )}
            
            </Dropdown.Menu>
            
            </Dropdown>
            
            }
            <Card.Text>Product status</Card.Text>

</Col>

            </Row>
            <br/>

            <Row>
            {editable ? 
            <>
            <Col>
            <Button variant="success" onClick={()=> clickAdd()}>Save changes</Button>
            </Col>
            
            <Col>
            
            <Button variant="danger" onClick={()=> setEditable(!editable)}>Delete product</Button>
            </Col>
            
            </>
            : 
            <>
            <Col>
            <Button onClick={()=> prepareForEdit()}>Edit</Button>
            </Col>
            <Col/>
            
            </>
            
            }
            </Row>

            </Card.Body>
        
        </Card>
    )
}


const AllTicketsList = () => {
    const [order, setOrder] = useState("desc")
    const [value, loading, error] = useCollection(
        firestore.collection('tickets')
        .orderBy("created", order).limit(10))

    if (error) return <GenericErrorAlert title="Can't fetch tickets." />

    return (
        <>
            <Dropdown className="mb-2">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Sort by
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item disabled={order !== "asc"} onClick={() => setOrder("desc")}>Sort by newest</Dropdown.Item>
                    <Dropdown.Item disabled={order !== "desc"} onClick={() => setOrder("asc")}>Sort by oldest</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {loading ? <GenericLoadingAnimation /> :
                <>
                    {value.docs.map(doc =>
                            
                            <ListGroupItem  hidden={(doc.data().solved) || (doc.data().assigned!=="none")} onClick={() => focusTicket(doc.id)} variant={determineTimestampColor(doc.data().created)} disabled={doc.data().solved} action key={doc.id}>#MEKANISK{doc.data().orderNumber} {"Created " + FirebaseTimestampSince(doc.data().created)}</ListGroupItem>

                    )}

                </>
            }

        </>
    )
}

const AllArchivedTicketsList = () => {
    const [value, loading, error] = useCollectionOnce(
        firestore.collection('tickets').orderBy("created", "desc"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })

    if (loading || error) return (<GenericLoadingAnimation />)

    return (

        <div>
            {value.docs.map(doc =>
                <>
                    {doc.data().solved ?
                        <ListGroupItem action key={doc.id}>#MEKANISK{doc.data().orderNumber} - Originally created {FirebaseTimestampSince(doc.data().created)} By customer {doc.data().email}, solved by {doc.data().assigned}</ListGroupItem>
                        : null}
                </>
            )}
        </div>
    )
}

function beautifyJSDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const FirebaseTimestampSince = (timestamp) => {
    var d = new Date()
    var td = timestamp.toDate()

    var timeDifference = d.getTime() - td.getTime()
    var differenceInDays = timeDifference / (1000 * 3600 * 24)
    var differenceInHours = differenceInDays * 24
    var differenceInMinutes = differenceInHours * 60
    var differenceInSeconds = differenceInMinutes * 60


    if (differenceInSeconds < 60) return Math.floor(differenceInSeconds) + " seconds ago."
    if (differenceInMinutes < 60) return Math.floor(differenceInMinutes) + " minutes(s) ago."
    if (differenceInHours < 24) return Math.floor(differenceInHours) + " hours ago."
    if (differenceInDays < 30) return Math.floor(differenceInDays) + " day(s) ago."
    else {
        return " over a month ago"
    }
}



const determineTimestampColor = (timestamp) => {
    var d = new Date()
    var td = timestamp.toDate()

    var timeDifference = d.getTime() - td.getTime()
    var differenceInDays = timeDifference / (1000 * 3600 * 24)
    var differenceInHours = differenceInDays * 24
    var differenceInMinutes = differenceInHours * 60
    var differenceInSeconds = differenceInMinutes * 60


    if (differenceInSeconds < 60) return "ultramarine"
    if (differenceInMinutes < 60) return "secondary"
    if (differenceInHours < 24) return "danger"
    if (differenceInDays < 30) return "danger"
    else {
        return "ultrahot"
    }
}

export { Faqlist, ProductList, AllTicketsList, AllArchivedTicketsList };

