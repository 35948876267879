import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { sendEmail } from '../database/db_email';
import { solveTicket } from '../database/db_ticket';
import assist_config from '../assist_config.json'




/**
 * Modal for sending emails
 * @component
 *
 */

const EmailModal = ({ show, setShow, email, orderNumber }) => {

  const [body, setBody] = useState("")
  const [subject, setSubject] = useState("Regarding your Mekanisk support ticket | #MEKANISK" + orderNumber)

  const handleEmailsend = ()=>{
    if(sendEmail(email, "<p>"+body+"</p>", subject)) {
      handleClose()
    }
  }
  const handleClose = () => setShow(false)
    return (
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send an email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <Form.Group>
            <Form.Label>To: {email}</Form.Label>
              </Form.Group>

            <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control placeholder="Subject" type="text" onChange={e => setSubject(e.target.value)} value={subject}></Form.Control>
              </Form.Group>

              <Form.Group>
              <Form.Label>Message</Form.Label>
                <Form.Control placeholder="Message" maxLength="255" as="textarea" rows={4} onChange={e => setBody(e.target.value)} value={body}></Form.Control>
              </Form.Group>
            </Form>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEmailsend}>Send email</Button>
        </Modal.Footer>
      </Modal>
    )
  
}


/**
 * Modal for confirming an action
 * @component
 *
 */

const ConfirmationModal = ({docId, show, setShow, setConfirm, title, body}) => {

  const [appendTicket, setAppendTicket] = useState("")


  async function solveAndSendEmail(){
    await solveTicket(docId, appendTicket)
    setShow(false)
  }

  return(
    <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}

            <Form>
        <Form.Group>
              <Form.Label className="text-muted">You should add a message to the solved ticket email. The customer will see this. Signature automatically added.</Form.Label>
              <Form.Label className="text-muted">ex. "We updated your address."</Form.Label>
                <Form.Control placeholder="Message" maxLength="155" as="textarea" rows={3} onChange={e => setAppendTicket(e.target.value)} value={appendTicket}></Form.Control>
              </Form.Group>
            </Form>
        </Modal.Body>

      
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button disabled={appendTicket===""} variant="primary" onClick={()=>solveAndSendEmail()}>Send message and archive ticket</Button>
        </Modal.Footer>
      </Modal>
  )
}

/**
 * Modal for reading the GDPR
 * @component
 *
 */

const GDPRmodal = ({show, setShow}) => {
  return(
    <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>GDPR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            We use your email in combination with your order number to save the ticket in our system. We will use your email to send you the initial
            email confirming ticket arrival. We will then use your email to contact you regarding this ticket if we need more information. At the end of the process
            we will use your email to send an email confirming that the ticket has been solved. 
            All the information provided to us is confidental, and will be saved 6 months or less from the ticket solved date.
        </Modal.Body>

      
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )
} 


export { EmailModal, ConfirmationModal, GDPRmodal };
