import React, {useEffect, useState} from 'react'
import { GenericLoadingAnimation } from '../comp/loadingAnimations'
import {app} from './firebase'

/**
 * AuthContext is a React Context component
 * @component
 */

export const AuthContext = React.createContext()


/**
 * Authprovider is a custom authentication provider that updates access to children based on Firebase authentication status.
 * @component
 */

export const AuthProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null)
    const [pending, setPending] = useState(true);

    useEffect(()=>{
        app.auth().onAuthStateChanged((user)=> {
            setCurrentUser(user)
            setPending(false)
        })
    }, [])

    if(pending){
        return <GenericLoadingAnimation/>
      }

    return (
        <AuthContext.Provider value={{currentUser}}>
        {children}
        </AuthContext.Provider>
    )
}