import { app, firestore } from './firebase'


const focusTicket = (documentId) => { 
  console.log(documentId)
  var user = app.auth().currentUser

    firestore.collection("tickets").doc(documentId).update({
    assigned: user.email,
    })
    .then(function () {
        return true
    })
    .catch(function (error) {
        return false
    })
}

const solveTicket = (documentId, appendMessage) => { 
    firestore.collection("tickets").doc(documentId).update({
        solved: true,
        solvedMessage: appendMessage
      })
      .then(function () {
    
    })
    .catch(function (error) {
        return false
    })
}




export { focusTicket, solveTicket }
