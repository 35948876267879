import { firestore } from './firebase'



const sendEmail = (emailAddress, body, subject)=>{

    firestore.collection("mail").add({
        to: emailAddress,
        message: {
          subject: subject,
          html: body,
        },
      })

    return true
}

export { sendEmail }
