import React, { useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { StandardCard, UserCard } from '../../comp/cards';
import { Menu } from '../../comp/menus';
import { app } from '../../database/firebase';

/**
 * Page showcasing the user information
 * @component
 *
 */

const UserPage = ({ history }) => {

  const resetPw = useCallback(async event => {
    try {
      await app
      .auth()
      .signOut()
      history.push('/resetPw')
    } catch (error){
      alert("an error occured")
    }
  }, [history])


  return (
    <>
      <Menu history={history} />
      <Container>
        <StandardCard title="My account">
          <UserCard/>
        </StandardCard>
        <StandardCard title="Settings"> 
          <Button onClick={()=> resetPw()}>Reset password</Button>
        </StandardCard>
      </Container>
    </>

  )
}

export default withRouter(UserPage)