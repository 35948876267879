
import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { withRouter } from 'react-router';
import { StandardCard } from '../../comp/cards';
import { Menu } from '../../comp/menus';
import { app } from '../../database/firebase';

/**
 * Page showcasing the sign up form for a new CS-worker
 * @component
 */

const SignUpPage = ({ history }) => {

    const click = useCallback(async event => {
        event.preventDefault()
        const { email, password } = event.target.elements
        try {
            await app
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value)
            history.push('/')
        } catch (error) {
            alert(error)
        }
    }, [history])


    return (
        <>
            <Menu history={history} />
            <Container>
                <StandardCard title="Create another Mekanisk Assist user account">

                    <Form onSubmit={click}>

                        <Form.Group>
                            <Form.Control name="email" type="email" placeholder="Email address"></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="email" placeholder="Repeat email address"></Form.Control>
                        </Form.Group>


                        <Form.Group>
                            <Form.Control name="password" type="password" placeholder="Choose a password" ></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="password" placeholder="Repeat password" ></Form.Control>
                        </Form.Group>
                        <Card className="mb-4 p-2" bg="danger">This user will not have access until CS role is given by Mekanisk Assist admins. Contact william@mekanisk.co to manually grant this role.</Card>
                        <Button variant="primary" type="submit">
                            Register account
                    </Button>

            
                    </Form>
                </StandardCard>
            </Container>
        </>

    )
}

export default withRouter(SignUpPage)