import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { StandardCard } from '../../comp/cards';
import { EditFAQForm } from '../../comp/forms';
import { Faqlist } from '../../comp/lists';
import { Menu } from '../../comp/menus';
/**
 * Page displaying the tools needed to update the FAQ on the frontpage
 * @component
 */
const EditFaqPage = ({ history }) => {


    return (
        <>
            <Menu history={history} />
            <Container>
        <Row>

        <Col>
                <StandardCard title={"Add questions to the customer FAQ"}>
                    <EditFAQForm />
                </StandardCard>
                
                
                </Col>
                

                <Col>
                <StandardCard title={"Frequently asked questions"}> <Faqlist live={true} editable={true} /> </StandardCard>
                </Col>
                



        </Row>
                

            </Container>
        </>
    )
}




export default EditFaqPage