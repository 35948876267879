import React, { useCallback, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { Redirect, withRouter } from 'react-router'
import { StandardCard } from '../comp/cards'
import { AuthContext } from '../database/Auth'
import { app } from '../database/firebase'
import { Nav, Navbar, Image, Card} from 'react-bootstrap';
import wp from '../assets/wp.JPG'

/**
 * Page showcasing the log  form
 * @component
 */


const LoginPage = ({history}) => {
    const click = useCallback(async event => {
        event.preventDefault()
        const {email, password} = event.target.elements
        
        try {
            await app
                .auth()
                .signInWithEmailAndPassword(email.value, password.value)
            history.push('/dashboard')
        } catch (error){
            alert("Wrong username or password")
        } 
    }, [history])

    const {currentUser} = useContext(AuthContext)

    if (currentUser) {
      return <Redirect to='/dashboard'/>
    }



  return (

    <div>
      <Container>

          <br/>
        <Card className="p-4 w-4">

        <Form onSubmit={click}>
            <Form.Group>
                <Form.Control name="email" type="email" placeholder="Email address"></Form.Control>
            </Form.Group>

            <Form.Group>
                <Form.Control name="password" type="password"  placeholder="Password" ></Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
                Log in
            </Button>
            
        </Form>

       
            <Nav.Link onClick={() => history.push('/resetpw')}>Forgot password</Nav.Link>
        
        
        </Card>

        
    
      </Container>

    

</div>
    
  )
}

export default withRouter(LoginPage)