import React from 'react';
import { Container } from 'react-bootstrap';
import { StandardCard } from '../../comp/cards';
import { AllArchivedTicketsList } from '../../comp/lists';
import { Menu } from '../../comp/menus';
/**
 * Page displaying the archived/solved tickets
 * @component
 */
const ArchivePage = ({ history }) => {

    return (
        <>
            <Menu history={history} />
            <Container>
                <div className="Archive">
                    <StandardCard title="Solved tickets">
                        <AllArchivedTicketsList />
                    </StandardCard>

                </div>
            </Container>
        </>
    )
}

export default ArchivePage